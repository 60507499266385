import React from "react";
import { Card } from "react-bootstrap";
import "../Css/components/ContactCard.css";

const ContactCard = ({ icon, desc, href, hrefDesc }) => {
  return (
    <Card className="contact-card">
      <Card.Body>
        <Card.Title className="text-center">
          <i className={`fa ${icon} hg`} aria-hidden="true"></i>
        </Card.Title>
        <Card.Text className="text-center">
          {desc}
          <div>
            <a className="support-phone" href={href}>
              {hrefDesc}
            </a>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ContactCard;
