import React, { useEffect } from "react";
import "../Css/components/Footer.css";
import Aos from "aos";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    Aos.init({
      duration: 1600,
      delay: 100,
    });
  }, []);
  return (
    <footer className="site-footer ">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-lg-auto col-md-8 col-10">
            {/* <img src={logo} width="70" /> */}
            <h1
              className="text-white footer-h1"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <strong>Gratus</strong> Tech
            </h1>
          </div>
          <div
            className="col-lg-3 col-md-6 col-12"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <h4 className="my-4">Navigation</h4>
            <p className="mb-1">
              <Link to="/">Home</Link>
            </p>
            <p className="mb-1">
              <Link to="/about">About Us</Link>
            </p>
            <p className="mb-1">
              <Link to="/services">Services</Link>
            </p>
            <p className="mb-1">
              <Link to="/portfolio">Portfolio</Link>
            </p>
            <p className="mb-1">
              <Link to="/contact">Contact Us</Link>
            </p>
          </div>
          <div
            className="col-lg-3 col-md-6 col-12"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <h4 className="my-4">Contact Info</h4>
            <p className="mb-1">
              <a href="tel:+919028367948">
                <i className="fa fa-phone mr-2 footer-icon" />
                +91 90283 67948
              </a>
            </p>
            <p>
              <a href="mailto:contact@gratustech.com">
                <i className="fa fa-envelope mr-2 footer-icon" />
                contact@gratustech.com
              </a>
            </p>
          </div>

          <div
            className="col-lg-4 mx-lg-auto text-center col-md-8 col-12"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <p className="copyright-text text-center">
              Copyright © 2020 Gratus Tech
            </p>
          </div>

          <div
            className="col-lg-3 mx-lg-auto col-md-6 col-12"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <ul className="social-icon text-center">
              <li>
                <a
                  href="https://www.facebook.com/gratustech"
                  className="fa fa-facebook"
                />
              </li>
              <li>
                <a
                  href="https://www.instagram.com/gratustech/"
                  className="fa fa-instagram"
                />
              </li>
              <li>
                <a
                  href="https://twitter.com/gratus_tech"
                  className="fa fa-twitter"
                />
              </li>
              <li>
                <a
                  href="https://wa.me/message/O7Y2D6BHHOZKB1"
                  className="fa fa-whatsapp"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
