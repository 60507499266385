import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

import { Link, withRouter } from "react-router-dom";
import logo from "../assest/logo.png";

const currentTab = (history, path) => {
  if (history.location.pathname === path) {
    return { color: "#4f4691" };
  } else {
    return { color: "Black " };
  }
};
const Header = ({ history, header_location }) => {
  return (
    <div>
      <Navbar className={header_location} bg="light" expand="lg">
        <Container>
          <Link className="home-link" to="/">
            <img src={logo} width="80px" height="85px" alt="gratus tech logo" />
            <p>Gratus Tech</p>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link
                style={currentTab(history, "/")}
                className="nav-link"
                to="/"
              >
                Home
              </Link>
              <Link
                style={currentTab(history, "/about")}
                className="nav-link"
                to="/about"
              >
                About us
              </Link>
              <Link
                style={currentTab(history, "/services")}
                className="nav-link"
                to="/services"
              >
                Services
              </Link>
              <Link
                style={currentTab(history, "/portfolio")}
                className="nav-link"
                to="/portfolio"
              >
                portfolio
              </Link>
              <Link
                style={currentTab(history, "/contact")}
                className="nav-link"
                to="/contact"
              >
                Contact Us
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default withRouter(Header);
