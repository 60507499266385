import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { contactForm } from "../api";

import ContactCard from "../Components/ContactCard";
import Base from "../Core/Base";
import "../Css/contact.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comapanyName, setComapanyName] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSucess] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const onContactSumbit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSucess(false);
    contactForm({ name, email, phoneNumber, comapanyName, message }).then(
      (data) => {
        if (data.error) {
          setError(data.error);
          setLoading(false);
        } else {
          setName("");
          setPhoneNumber("");
          setComapanyName("");
          setEmail("");
          setMessage("");
          setSucess(true);
          setLoading(false);
        }
      }
    );
  };

  const errorMessage = () => {
    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }
  };

  const sucessMessage = () => {
    if (sucess) {
      return <Alert variant="success">Form submitted sucessfully..</Alert>;
    }
  };
  return (
    <Base>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Contact at 9028367948 or Mail at contact@gratustech.com"
        />
      </Helmet>
      <div className="contact-heading">
        <h1>Get in Touch</h1>
        <p>
          Want to get in touch? We'd love to hear from you. Here's how you can
          reach us...
        </p>
      </div>

      <div className="container">
        <div className="row">
          <ContactCard
            icon="fa-phone fa-ph"
            desc="Just pick up the phone to chat with us."
            href="tel:+919028367948"
            hrefDesc="+91 90283 67948"
          />
          <div className="col-12 col-lg-4">
            <ContactCard
              icon="fa-envelope-o"
              desc="Email Us on"
              href="mailto:contact@gratustech.com"
              hrefDesc="contact@gratustech.com"
            />
          </div>
          <div className="col-12 col-lg-4">
            <ContactCard
              icon="fa-whatsapp fa-whatsapps"
              desc="Whatsapp Us on"
              href="https://wa.me/message/O7Y2D6BHHOZKB1"
              hrefDesc="+91 90283 67948"
            />
          </div>
        </div>

        <div className="contact card">
          <p>
            If you’d like to talk to us about a project or receive a quote,
            please fill the form below. We will get in touch with your asap to
            discuss your requirements.
          </p>
          {errorMessage()}
          {sucessMessage()}
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Control
                    placeholder="Company Name"
                    onChange={(e) => {
                      setComapanyName(e.target.value);
                    }}
                    value={comapanyName}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Control
                type="email"
                placeholder="Email Address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Contact Number"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                value={phoneNumber}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Your Message.."
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
              />
            </Form.Group>
            <button
              className="btn btn-info"
              onClick={onContactSumbit}
              disabled={loading}
            >
              {loading ? "Please Wait.." : "Submit"}
            </button>
          </Form>
        </div>
      </div>
    </Base>
  );
};

export default Contact;
