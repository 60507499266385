import React from "react";
import "../Css/components/WorkCard.css";

const WorkCard = ({ img, link, name }) => {
  return (
    <div className="card work">
      <div className=" work-card">
        <a href={link} target="_blank" rel="noreferrer" className="text-center">
          <img className="work-img" src={img} alt="service img" />
        </a>
      </div>
      <p className="text-center mt-3">{name}</p>
    </div>
  );
};

export default WorkCard;
