import React, { useEffect } from "react";
import Base from "../Core/Base";
import "../Css/Services.css";
import web from "../assest/web.jpg";
import ServicesInfo from "../Components/ServicesInfo";
import seo from "../assest/seo.jpg";
import device from "../assest/device.jpg";
import mobile from "../assest/mobile.jpg";
import upgrade from "../assest/upgrade.jpg";
// import web from "../assest/web.jpg";

import "../Css/components/serviceInfo.css";

const Services = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Base>
      <div className="services-heading">
        <h1>Our Services</h1>
      </div>
      <div className="container">
        <ServicesInfo title="Website Development" img={device}>
          <li>Website development</li>
          <li>Corporate website development</li>
          <li>Business website development</li>
          <li> Personal blog development Company</li>
          <li>Ecommerce website development</li>
          <li>blog development</li>
          <li>Custome website development</li>
        </ServicesInfo>
        <ServicesInfo title="Custom Web Application" img={web}>
          <li>Customer Relationship Management (CRM) System</li>
          <li>Business Process Automation System</li>
          <li>Paperless Office</li>
          <li>Small business web applications</li>
        </ServicesInfo>
        <ServicesInfo id="mobile" title="Mobile App Development" img={mobile}>
          <li>Ecommerce (website + App)</li>
          <li>Online food delivery app like Swiggy or Ubar Eats</li>
          <li>B2B ordering app for distributors of goods</li>
          <li>Ordering app for Restaurants</li>
          <li>Hotel booking apps like MakeMyTrip or GoIbibo</li>
        </ServicesInfo>
        <ServicesInfo img={upgrade} title="Upgrade/Maintenance Website">
          <li>
            Uploading new text content, image, news and updating existing
            content, image, news
          </li>
          <li>
            Adding new information releases, deleting or altering existing
            information releases
          </li>
          <li>Managing contact us pages, updating map location</li>
          <li>Minor graphics creation for website</li>
          <li>
            Adding new navigation menu, deleting or altering existing navigation
            menu
          </li>
        </ServicesInfo>

        <ServicesInfo title="SEO" img={seo}>
          <li>Meta tag optimization</li>
          <li>Source code, JS optimization</li>
          <li>XML Sitemap creation</li>
          <li>404 error page optimization</li>
          <li>Directory submission</li>
          <li>Local directory submission on search engines</li>
          <li>Social booking integration</li>
        </ServicesInfo>
      </div>
    </Base>
  );
};

export default Services;
