import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import About from "./Page/About";
import Contact from "./Page/Contact";
import Home from "./Page/Home";
import Portfoilo from "./Page/Portfoilo";
import Services from "./Page/Services";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/about" exact component={About} />
        <Route path="/services" exact component={Services} />
        <Route path="/portfolio" exact component={Portfoilo} />

        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
