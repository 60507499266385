import React from "react";
import { Row, Col } from "react-bootstrap";

const ServicesInfo = ({ title, children, img, alt }) => {
  return (
    <div data-aos="zoom-in" className="card service-info-card">
      <h3 className="text-center mt-2">{title}</h3>
      <div className="services-title__border"></div>
      <div className="service-info">
        <Row>
          <Col lg={5}>
            <img src={img} alt="web Development" width="250" />
          </Col>
          <Col lg={7}>
            <ol>{children}</ol>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ServicesInfo;
