import React, { useEffect } from "react";
import Base from "../Core/Base";
import moolchand from "../assest/moolchand.jpg";
import primecare from "../assest/primecarephysio.jpg";
import mathislife from "../assest/mathislife.jpg";
import msolution from "../assest/msolution.jpg";
import WorkCard from "../Components/WorkCard";
import "../Css/Portfoilo.css";
import { Helmet } from "react-helmet";

const Portfoilo = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Base>
      <Helmet>
        <title>Portfoilo</title>
        <meta name="description" content="Some of our work" />
      </Helmet>
      <div className="portfoilo-heading">
        <h1>Our Portfolio</h1>
      </div>
      <div className="container">
        <div className="row mt-3">
          <div className="col-lg-4">
            <WorkCard
              img={moolchand}
              link="https://www.moolchandsons.com"
              name="Moolchand Sons"
            />
          </div>
          <div className="col-lg-4">
            <WorkCard
              img={msolution}
              link="https://www.msolutions.net.in/"
              name="M solutions"
            />
          </div>
          <div className="col-lg-4">
            <WorkCard
              img={primecare}
              link="https://primecarephysio.co.uk/"
              name="Primecare Physiotherapy Clinic"
            />
          </div>
          <div className="col-lg-4 offset-lg-4">
            <WorkCard
              img={mathislife}
              link="https://mathislife.in/"
              name="MathIsLife"
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Portfoilo;
