import React from "react";
import "../Css/components/why.css";
const WhyCard = ({ img, desc, title, aos, delay }) => {
  return (
    <div className="card why" data-aos={aos} data-aos-delay={delay}>
      <div className="why-card">
        <img src={img} alt="why choose us" />
        <div className="content">
          <h4>{title}</h4>
          <p>{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default WhyCard;
