import React from "react";
import { Link } from "react-router-dom";
import "../Css/components/Services.css";

const ServicesCard = ({ title, img, alt, desc }) => {
  return (
    <Link className="services-link" to="/services">
      <div className="card services-card">
        <img className="card-icon" src={img} alt={alt}></img>

        <h4 className="text-center">{title}</h4>
        <p>{desc}</p>
      </div>
    </Link>
  );
};

export default ServicesCard;
