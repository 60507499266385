import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Base = ({ children, className }) => {
  return (
    <div>
      <a
        href="https://wa.me/message/O7Y2D6BHHOZKB1"
        class="float"
        target="_blank"
      >
        <i class="fa fa-whatsapp my-float"></i>
      </a>
      <Header header_location={className} />
      {children}
      <Footer />
    </div>
  );
};

export default Base;
