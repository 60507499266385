import React, { useEffect } from "react";
import Base from "../Core/Base";
import "../Css/Home.css";
import medition from "../assest/meditating.png";
import icon from "../assest/icon.png";
import { Container } from "react-bootstrap";
import ServicesCard from "../Components/ServicesCard";
import upgrade from "../assest/upgrade.jpg";
import device from "../assest/device.jpg";
import seo from "../assest/seo.jpg";
import WorkCard from "../Components/WorkCard";
import moolchand from "../assest/moolchand.jpg";
import primecare from "../assest/primecarephysio.jpg";
import mathislife from "../assest/mathislife.jpg";
import msolution from "../assest/msolution.jpg";
import mobile from "../assest/mobile.jpg";
import digital from "../assest/digital.jpg";
import web from "../assest/web.jpg";
import whychoose from "../assest/businessman-laptop.jpg";
import Aos from "aos";
import support from "../assest/support_1.jpg";
import think from "../assest/think.jpg";
import delivery from "../assest/person.jpg";
import "aos/dist/aos.css";
import WhyCard from "../Components/WhyCard";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
    Aos.init({
      duration: 1600,
      delay: 100,
    });
  }, []);
  return (
    <Base className="home-navbar">
      <Helmet>
        <title>Gratus Tech</title>
      </Helmet>
      <section className="master-head">
        <div className="container">
          <div className="row ">
            <div className="col-lg-7">
              <div className="home-title ">
                <h1 data-aos="fade-right">The Right way to Digital</h1>
                <p data-aos="fade-right" data-aos-delay="300">
                  We design and build innovative digital products that connect
                  to the real world.
                </p>

                <div data-aos="fade-right" data-aos-delay="500">
                  <Link to="/contact" className="btn btn-info mr-2">
                    More info
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-5 text-center">
              <div className="master-img" data-aos="fade-left">
                <div className="master-social-img flyIn">
                  <img className="img-fluid" src={icon} alt="icons" />
                </div>
                <img
                  className="img-fluid"
                  src={medition}
                  alt="Gratus Tech businessman "
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="services-title text-center" data-aos="zoom-in">
          <h2>
            All-in-One Web Solution
            <br /> for Your Business
          </h2>

          <div className="services-title__border"></div>
        </div>
        <Container>
          <div className="row">
            <div className="col-lg-4" data-aos="zoom-in-down">
              <ServicesCard title="Web Development" img={device} />
            </div>
            <div
              className="col-lg-4"
              data-aos="zoom-in-down"
              data-aos-delay="200"
            >
              <ServicesCard title="Custom Web Application" img={web} />
            </div>
            <div
              className="col-lg-4"
              data-aos="zoom-in-down"
              data-aos-delay="400"
            >
              <ServicesCard title="Mobile App Development" img={mobile} />
            </div>
            <div className="col-lg-4" data-aos="zoom-in-down">
              <ServicesCard title="Upgrade/Maintenance website" img={upgrade} />
            </div>
            <div
              className="col-lg-4"
              data-aos="zoom-in-down"
              data-aos-delay="200"
            >
              <ServicesCard title="SEO" img={seo} />
            </div>

            <div
              className="col-lg-4"
              data-aos="zoom-in-down"
              data-aos-delay="400"
            >
              <ServicesCard title="Digital Marketing" img={digital} />
            </div>
          </div>
        </Container>
      </section>
      <section className="why-choose">
        <div className="services-title text-center">
          <h2>Why Choose us</h2>
          <div className="services-title__border"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <img
                src={whychoose}
                alt="Why choose us"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-lg-5">
              <WhyCard
                aos="fade-up"
                img={think}
                title="Unique Approach"
                desc="We use a unique approach to all our projects"
              />
              <WhyCard
                aos="fade-up"
                delay="100"
                img={delivery}
                title="100% Client Satisfaction"
                desc="Our client's satisfaction is our reward."
              />
              <WhyCard
                delay="200"
                aos="fade-up"
                img={support}
                title="Support"
                desc="We provide the continual delivery of superior technical support"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container mb-2">
        {" "}
        <div className="text-center">
          <h2>Some of our Work</h2>
          <div className="services-title__border"></div>
        </div>
        <div className="row ">
          <div className="col-lg-4">
            <WorkCard
              img={moolchand}
              link="https://www.moolchandsons.com"
              name="Moolchand Sons"
            />
          </div>
          <div className="col-lg-4">
            <WorkCard
              img={msolution}
              link="https://www.msolutions.net.in/"
              name="M   Solutions"
            />
          </div>
          <div className="col-lg-4">
            <WorkCard
              img={primecare}
              link="https://primecarephysio.co.uk/"
              name="Primecare Physiotherapy Clinic"
            />
          </div>
          <div className="col-lg-4 offset-lg-4">
            <WorkCard
              img={mathislife}
              link="https://mathislife.in/"
              name="MathIsLife"
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Home;
