import React, { useEffect } from "react";
import Base from "../Core/Base";
import "../Css/About.css";
import { Helmet } from "react-helmet";
import moolchandsons from "../assest/Clinets/moolchand.jpg";
import msolutions from "../assest/Clinets/msolutions.jpg";
import primecarephysio from "../assest/Clinets/primecarephysio.jpg";
import mathislife from "../assest/Clinets/mathislife.jpg";

const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Base>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Gratus Tech was founded by Harshad Birajdar with aim of providing quality web development services to business owners all over the world."
        />
      </Helmet>
      <div className="about-heading">
        <h1>About Us</h1>
      </div>
      <div className="container about-container card" data-aos="zoom-in">
        <p className="about-p">
          Gratus Tech was founded by Harshad Birajdar with aim of providing
          quality web development services to business owners all over the
          world.
          <br /> <br /> Choosing us would ensure you get your service/product
          developed by a few of the primo minds in the industry. We are
          committing to rendering excellence services and peerless solutions to
          clients is the main motto of our business.
          <br /> <br />
          We create custom web applications from scratch, so your website is
          unique to you. Our websites are built with modern framework that is
          fully responsive and we take care of your security and hosting.
          <br />
          <br />
          We have a skilled team that is capable to handle any complex task
          during the designing and development process. We also provide IT
          outsourcing services all over the world which includes services like
          web development, mobile app development, SEO, and UI/UX designing.
        </p>
      </div>
      <div className="container" style={{ marginTop: "3em" }}>
        <div className="text-center">
          <h2>Our Clients</h2>
          <div className="services-title__border"></div>
        </div>
        <div className="row text-center" data-aos="fade-up">
          <div className="col-lg-4">
            <img
              className="clinets-logo"
              src={moolchandsons}
              alt="moolchandsons"
            />
          </div>
          <div className="col-lg-4">
            <img className="clinets-logo" src={msolutions} alt="M Solutions" />
          </div>
          <div className="col-lg-4">
            <img
              className="clinets-logo"
              src={primecarephysio}
              alt="Primecarephysio"
            />
          </div>
          <div className="col-lg-4 offset-lg-4">
            <img className="clinets-logo" src={mathislife} alt="mathislife" />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default About;
